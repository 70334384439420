import React, { useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import DefaultButton from '../../components/buttons/default-button/default-button';
import API_BASE_URL from '../../config';
import './login.css';

function Login() {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();  // 📌 Speichert die aktuelle URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const dataToSend = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    };

    try {
      const endpoint = isRegistering ? 'register/' : 'login/';
      const response = await fetch(`${API_BASE_URL}/api/auth/${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      const data = await response.json();
      if (response.ok) {
        if (isRegistering) {
          navigate('/verify-email');  // 🔹 Nach Registrierung auf Bestätigungsseite weiterleiten
        } else {
          login(data.token, data.user);  // 🔹 Benutzer einloggen
          const redirectPath = location.state?.from || '/';  // 🔹 Zur vorherigen Seite zurückleiten
          navigate(redirectPath);
        }
      } else {
        setError(data.detail || 'Anmeldung fehlgeschlagen');
      }
    } catch (err) {
      setError('Server nicht erreichbar. Bitte versuche es später erneut.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='auth-container'>
      <div className='auth-box'>
        <h2>{isRegistering ? 'Registrierung' : 'Login'}</h2>
        <form onSubmit={handleSubmit}>
          <div className='input-group'>
            <input
              className='login-input'
              type='email'
              placeholder='E-Mail'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='input-group'>
            <input
              className='login-input'
              type='password'
              placeholder='Passwort'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {isRegistering && (
            <>
              <div className='input-group'>
                <input
                  className='login-input'
                  type='text'
                  placeholder='Vorname'
                  name='first_name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className='input-group'>
                <input
                  className='login-input'
                  type='text'
                  placeholder='Nachname'
                  name='last_name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          {error && <p className='error-message'>{error}</p>}
          <DefaultButton type='submit' disabled={loading} text={isRegistering ? 'Registrieren' : 'Einloggen'}>
            {loading ? 'Bitte warten...' : isRegistering ? 'Registrieren' : 'Login'}
          </DefaultButton>
        </form>
        <p className='toggle-auth' onClick={() => setIsRegistering(!isRegistering)}>
          {isRegistering ? 'Bereits ein Konto? Einloggen' : 'Noch kein Konto? Registrieren'}
        </p>
        <p>
          <Link to='/forgot-password' className='forgot-password-link'>Passwort vergessen?</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
