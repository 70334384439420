import React, { useState } from "react";
import API_BASE_URL from "../../config";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/password-reset/`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            setMessage(data.message || data.error);
        } catch (err) {
            setMessage("Fehler beim Senden der Anfrage.");
        }
    };

    return (
        <div className="auth-container">
            <h2>Passwort zurücksetzen</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    className="login-input"
                    name="email"
                    placeholder="E-Mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">E-Mail senden</button>
            </form>
            <p>{message}</p>
        </div>
    );
}

export default ForgotPassword;
