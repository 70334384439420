import React, { useEffect, useState } from 'react';
import CourseElement from '../../components/training-items/course-element';
import API_BASE_URL from '../../config';
import '../../assets/css/landingpages.css';

function ITSupport() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/landing-page/it-support`)
      .then(response => response.json())
      .then(data => setPageData(data))
      .catch(error => console.error("Fehler beim Laden der Landingpage:", error));
  }, []);
  console.log("📩 API-Daten:", pageData);
  if (!pageData) return <p>Lädt...</p>;

  return (
    <div className='software-dev-container'>
      {/* Hero Section */}
      <section className='hero-section'
      style={{ background: `url(${pageData.hero_image_path}) center/cover no-repeat` }}>
        <div className='hero-overlay'></div>
        <div className='hero-content'>
          <h1>{pageData.hero_title}</h1>
          <p>{pageData.hero_text}</p>
        </div>
      </section>

      {/* Features Section */}
      <section className='features-section'>
        {pageData.features.map((feature) => (
          <CourseElement
            key={feature.id}
            logo_path={`/${feature.image_path}`}
            title={feature.title}
            course_count={feature.description}
            slug={feature.slug}
            btn_text='Mehr erfahren'
          />
        ))}
      </section>
    </div>
  );
}

export default ITSupport;
