import './toggle-switch.css';

function ToggleSwitch(props) {
    return (
        <div>
            <input className='switch-input' type='checkbox' id='dark-mode-toggle' onChange={props.onChange} checked={props.checked} />
            <label className='switch-label' htmlFor='dark-mode-toggle' ></label>
        </div>
    );
}

export default ToggleSwitch;
