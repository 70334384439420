import React from 'react'
import { Link } from 'react-router-dom'

import './course-element.css'

import DefaultButton from '../buttons/default-button/default-button.jsx'

function CourseElement(props) {
  return (
    <div className='card-container'>
      <div className="img-container">
        <img className="card-image" src={props.logo_path} alt={props.title} />
      </div>
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">{props.course_count}</p>
        <Link to={props.slug}>
          <DefaultButton class='card-button' text={props.btn_text}></DefaultButton>
        </Link>
      </div>
    </div>
  )
}

export default CourseElement
