import classes from './home.module.css';
import CourseElement from '../../components/training-items/course-element.jsx';

function Home() {
    return (
        <div className={classes.homeContainer}>
            {/* Hero Section */}
            <section className={classes.sectionHero}>
                <div className={classes.overlay}></div>
                <div className={classes.contentBox}>
                    <h1 className={classes.homeH1}>keep IT simple</h1>
                    <h3 className={classes.homeH3}>Wir kümmern uns um die Technik – damit Sie sich auf Ihr Geschäft konzentrieren können.</h3>
                </div>
            </section>

            {/* Schulungen, Softwareentwicklung, IT-Support */}
            <section className={classes.sectionInfo}>
                <CourseElement
                    logo_path="/assets/logo/icons8-bildung-100.png"
                    title="IT-Schulungen"
                    course_count="Praxisnahe Schulungen für Unternehmen und Einzelpersonen."
                    slug="/training-courses-lp"
                    btn_text='Zu den Kursen'
                />

                <CourseElement
                    logo_path="/assets/logo/icons8-programmierung-100.png"
                    title="Softwareentwicklung"
                    course_count="Individuelle Softwarelösungen für Ihre Geschäftsprozesse."
                    slug="/software-development"
                    btn_text='Softwareentwicklung'
                />

                <CourseElement
                    logo_path="/assets/logo/icons8-computer-support-100.png"
                    title="IT-Support"
                    course_count="Wir helfen Ihnen bei technischen Problemen – schnell und zuverlässig."
                    slug="/it-support"
                    btn_text='IT-Support'
                />
            </section>
        </div>
    );
}

export default Home;
