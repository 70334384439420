import classes from './qr.module.css'

import { useEffect } from 'react';
import { Link } from "react-router-dom";


function QrCode() {
    useEffect(() => {
        const logoContainer = document.querySelector(`.${classes.logoContainer}`);
        const buttonContainer = document.querySelector('button');
        const contentContainer = document.querySelector(`.${classes.contentContainer}`);


        if (logoContainer && contentContainer && buttonContainer) {

          logoContainer.classList.add(classes.loaded);

          setTimeout(() => {
            buttonContainer.classList.add(classes.pulsing);
            buttonContainer.classList.add(classes.loaded);
            contentContainer.classList.add(classes.loaded);
          }, 2000);
        }
      }, []);

    return (
        <header>
            <div className={classes.logoContainer}>
                <img src="/assets/logo/base_logo_transparent_background.png" alt="Logo" />
            </div>

            <div className={classes.contentContainer}>
                <p>Du bist noch einen Klick<br />von deiner IT-Lösung entfernt</p>
            </div>

            <Link to="/">
                <button className={classes.btn}>Klick mich</button>
            </Link>
        </header>
    )
}

export default QrCode;
