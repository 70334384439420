import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_BASE_URL from "../../config";

function VerifyEmail() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetch(`${API_BASE_URL}/api/auth/verify-email/${token}/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    setMessage("E-Mail erfolgreich verifiziert! Du kannst dich jetzt einloggen.");
                    setTimeout(() => navigate("/login"), 3000);  // Weiterleitung nach 3 Sekunden
                } else {
                    setMessage("Ungültiger oder abgelaufener Verifizierungslink.");
                }
            })
            .catch(() => setMessage("Ein Fehler ist aufgetreten."));
    }, [token, navigate]);

    return (
        <div>
            <h2>{message}</h2>
        </div>
    );
}

export default VerifyEmail;
