import { Link } from 'react-router-dom';
import classes from './footer.module.css';

function Footer() {
    return (
        <footer className={classes.footerContainer}>
            <div className={classes.footerContent}>
                <p>&copy; {new Date().getFullYear()} VoDiCo. Alle Rechte vorbehalten.</p>
                <nav>
                    <Link to="/impressum">Impressum</Link>
                    <Link to="/datenschutz">Datenschutz</Link>
                    <Link to="/agb">AGB</Link>
                    <Link to="/contact">Kontakt</Link>
                </nav>
            </div>
        </footer>
    );
}

export default Footer;
