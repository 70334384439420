import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./AuthContext.js";  // Import AuthProvider
import QrCode from "./pages/qr-start/qr.jsx";
import Home from "./pages/home/home.jsx";
import About from "./pages/about/about.jsx";
import ItSupport from "./pages/it-support/it-support.jsx";
import SoftwareDevelopment from "./pages/software-development/software-development.jsx";
import TrainingCourses from "./pages/training-courses/training-courses.jsx";
import Navigation from "./pages/navigation/navigation.jsx";
import Courses from "./pages/courses/courses.jsx";
import Dates from "./pages/dates/dates.jsx";
import Footer from "./components/footer/footer.jsx";
import Impressum from "./pages/impressum/impressum.jsx";
import Login from "./pages/login/login.jsx";
import VerifyEmail from "./pages/verify-email/verifiy-email.jsx";
import WaitForVerify from "./pages/verify-email/wait-for-verify.jsx";
import ForgotPassword from "./pages/forgot-password/forgot-password.jsx";
import ResetPassword from "./pages/forgot-password/reset-password.jsx";
import TrainingCoursesLP from "./pages/training-courses-lp/training_courses-lp.jsx";
import BookingSummary from "./pages/booking/booking-summary.jsx";
import ThankYou from "./pages/thank-you/thank-you.jsx";
import Profile from "./pages/profile/profile.jsx";
import Contact from "./pages/contact/contact.jsx";
import Datenschutz from "./pages/datenschutz/datenschutz.jsx";

function App() {
    const location = useLocation();
    const hideNavRoutes = ["/qr"];
    const shouldHideNav = hideNavRoutes.some(route => location.pathname.startsWith(route));

    return (
        <AuthProvider>
            <div id="root">
                {!shouldHideNav && <Navigation />}

                <div className="mainContent">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/qr" element={<QrCode />} />
                        <Route path="/it-support" element={<ItSupport />} />
                        <Route path="/software-development" element={<SoftwareDevelopment />} />
                        <Route path="/training-courses-lp/online-schulungen" element={<TrainingCourses />} />
                        <Route path="/training-courses-lp" element={<TrainingCoursesLP />} />
                        <Route path="/training-courses-lp/online-schulungen/:courseCategory" element={<Courses />} />
                        <Route path="/training-courses/:courseCategory/:courseDates" element={<Dates />} />
                        <Route path="/impressum" element={<Impressum />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/verify-email" element={<WaitForVerify />} />
                        <Route path="/verify-email/:token" element={<VerifyEmail />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                        <Route path="/booking/:dateId" element={<BookingSummary />} />
                        <Route path="/thank-you" element={<ThankYou />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/datenschutz" element={<Datenschutz />} />

                    </Routes>
                </div>

                {!shouldHideNav && <Footer />}
            </div>
        </AuthProvider>
    );
}

export default App;
