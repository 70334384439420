import React, { useEffect, useState, useContext } from 'react';
import { useParams, NavLink} from 'react-router-dom';
import API_BASE_URL from '../../config';
import DefaultButton from '../../components/buttons/default-button/default-button';
import { AuthContext } from '../../AuthContext';
import './dates.css';

function Dates() {
  const { courseCategory, courseDates } = useParams();
  const [dates, setDates] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (courseDates) {
      fetch(`${API_BASE_URL}/api/training-courses/${courseCategory}/${courseDates}`)
        .then(response => response.json())
        .then(data => setDates(data))
        .catch(error => console.error('Error fetching dates:', error));
    }
  }, [courseCategory, courseDates]);

  const filteredDates = dates.filter(date => !date.expired);

  return (
    <div className="dates-container">
      {courseCategory.toUpperCase()}
      {filteredDates.length > 0 ? (
        <>
          <h2 className="course-title">{filteredDates[0]?.courses_title || 'Kurs Titel nicht verfügbar'}</h2>
          {filteredDates.map(date => (
            <div className="course-dates-card" key={date.id}>
              <div className="date-details">
                <p><strong>Startdatum:</strong> {date.formatted_start_date}</p>
                <p><strong>Enddatum:</strong> {date.formatted_end_date}</p>
                <p><strong>Uhrzeit:</strong> {date.start_time.split(':').slice(0, 2).join(':')} - {date.end_time.split(':').slice(0, 2).join(':')} Uhr</p>
                <p><strong>Netto Preis:</strong> {date.net_price} €</p>
                <p><strong>Preis (inkl. MwSt.):</strong> {date.total_price} €</p>

              </div>
              <NavLink to={user ? `/booking/${date.id}` : '/login'} state={{ from: `/training-courses/${courseCategory}/${courseDates}` }}>
                <DefaultButton text={user ? 'Jetzt Buchen' : 'Zum Buchen einloggen'} />
              </NavLink>
            </div>
          ))}
        </>
      ) : (
        <p>Keine verfügbaren Termine.</p>
      )}
    </div>
);
}

export default Dates;
