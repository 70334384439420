import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import ToggleSwitch from '../../components/checkbox/switch/toggle-switch';
import API_BASE_URL from "../../config";

import './navigation.css';

function Navigation() {
    const { user, logout } = useContext(AuthContext);  // AuthContext für Nutzerstatus
    const [navLinks, setNavLinks] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    // API-Daten abrufen
    const fetchNavLinks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/mainmenu/`);
            const data = await response.json();
            setNavLinks(data);
        } catch (error) {
            console.error("Fehler beim Abrufen der API-Daten:", error);
        }
    };

    useEffect(() => {
        fetchNavLinks();
    }, []);

    const getPreferredScheme = () => {
        const preferredScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return preferredScheme ? 'dark' : 'light';
    };

    const [theme, setTheme] = useState(() => {
        return localStorage.getItem('theme') || getPreferredScheme();
    });

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <nav className='nav-container'>
            <div className='logo-container'>
                <Link className='nav-link' to='/'>
                    <img src='/assets/logo/logo_basic_transparent.png' alt='Logo' />
                </Link>
            </div>

            {/* Burger-Menü Button */}
            <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
                <div></div>
                <div></div>
                <div></div>
            </button>

            {/* Navigations-Links */}
            <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
                {navLinks.map((link) => (
                    <NavLink
                        key={link.id}
                        className={({ isActive }) => `nav-link ${isActive ? 'gradient-text' : ''}`}
                        to={link.menu_path}
                        onClick={() => setMenuOpen(false)} // Menü schließen, wenn ein Link geklickt wird
                    >
                        <li className='gradient-text-hover'>
                            {link.menu_item}
                        </li>
                    </NavLink>
                ))}

                {/* Dynamischer Login/Logout-Button */}
                {!user ? (
                    <NavLink className='nav-link' to='/login' onClick={() => setMenuOpen(false)}>
                        <li className='gradient-text-hover'>Login</li>
                    </NavLink>
                ) : (
                    <NavLink className='nav-link' to='/profile' onClick={() => setMenuOpen(false)}>
                        <li className='gradient-text-hover'>Hallo {user.first_name}</li>
                    </NavLink>
                )}
            </ul>

            {/* Toggle Switch für Dark/Light Mode */}
            <div className='toggle-container'>
                <ToggleSwitch onChange={toggleTheme} checked={theme === 'dark'} />
            </div>
        </nav>
    );
}

export default Navigation;
