import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import DefaultButton from '../../components/buttons/default-button/default-button';
import classes from './courses.module.css';
import API_BASE_URL from '../../config';

function Courses() {
  const { courseCategory } = useParams();
  const [courses, setCourses] = useState([]);
  const [expandedCourseIds, setExpandedCourseIds] = useState([]); // State für expandierte Kurse


  console.log("Current courseCategory:", courseCategory);
  useEffect(() => {
    fetch(`${API_BASE_URL}/api/training-courses/${courseCategory}?sort=asc`)
      .then(response => response.json())
      .then(data => setCourses(data))
      .catch(error => console.error('Error fetching courses:', error));
  }, [courseCategory]);
  console.log("Current courses:", courses);

  const toggleExpand = (courseId) => {
    setExpandedCourseIds((prev) =>
      prev.includes(courseId)
        ? prev.filter(id => id !== courseId)  // Einklappen
        : [...prev, courseId]  // Aufklappen
    );
  };

  return (
    <div className={classes.courseContainer}>
      <h1 className={classes.coursesh1}>
        {courses.length > 0 ? courses[0].training_course_title : ''} Kurse
      </h1>

      {courses.map(course => (
        <div key={course.id} className={`${classes.courseCard} ${expandedCourseIds.includes(course.id) ? classes.expanded : ''}`}>
          <div className={classes.cardHeader} onClick={() => toggleExpand(course.id)}>
            <h2 className={classes.courseTitle}>{course.title}</h2>
            <button className={classes.toggleButton}>
              {expandedCourseIds.includes(course.id) ? '▲' : 'Kursinformationen ▼'}
            </button>
          </div>

          <div className={`${classes.courseDescription} ${expandedCourseIds.includes(course.id) ? classes.expanded : ''}`}
            dangerouslySetInnerHTML={{ __html: course.description }}>
          </div>

          <div className={classes.buttonContainer}>
            <Link to={`/training-courses/${courseCategory}/${course.course_slug}`}>
              <DefaultButton className={classes.courseButton} text="Zu den Terminen"></DefaultButton>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Courses;
