import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_BASE_URL from "../../config";

function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/reset-password/`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token, password }),
            });

            const data = await response.json();
            setMessage(data.message || data.error);

            if (response.ok) {
                setTimeout(() => navigate("/login"), 3000);
            }
        } catch (err) {
            setMessage("Fehler beim Zurücksetzen des Passworts.");
        }
    };

    return (
        <div className="auth-container">
            <h2>Neues Passwort setzen</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    className="login-input"
                    name="password"
                    placeholder="Neues Passwort"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Passwort speichern</button>
            </form>
            <p>{message}</p>
        </div>
    );
}

export default ResetPassword;
