import classes from './default-button.module.css'

function DefaultButton(props) {
    return (
        <button className={`${classes.default} ${props.className}`}
        onClick={props.onClick}>{props.text}</button>
    )
}

export default DefaultButton;
