import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import DefaultButton from '../../components/buttons/default-button/default-button';
import API_BASE_URL from '../../config';
import './profile.css';

function Profile() {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [customerProfile, setCustomerProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        fetch(`${API_BASE_URL}/api/auth/customer-profile/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            setCustomerProfile(data);
            setFormData({
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                company_name: data.is_private_person ? "" : data.company_name || "",
                street: data.street || "",
                house_no: data.house_no || "",
                city: data.city || "",
                zip_code: data.zip_code || "",
                tax_id: data.is_private_person ? "" : data.tax_id || "",
                is_private_person: data.is_private_person,
                is_company: data.is_company
            });
        })
        .catch(error => console.error('Fehler beim Laden des Profils:', error));
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        fetch(`${API_BASE_URL}/api/auth/customer-profile/`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
                user: {
                    first_name: formData.first_name,
                    last_name: formData.last_name
                }
            })
        })
        .then(response => response.json())
        .then(data => {
            setCustomerProfile(data);
            setIsEditing(false);
        })
        .catch(error => console.error('Fehler beim Speichern:', error));
    };

    return (
        <div className="profile-page">
            <div className="profile-container">
                <h2>Mein Profil</h2>

                {isEditing && formData ? (
                    <div className="profile-form">
                        {!formData.is_private_person && (
                            <>
                                <label htmlFor='company_name'>Firmenname</label>
                                <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} placeholder="Firmenname" />
                                <label htmlFor='tax_id'>Umsatzsteuer-ID</label>
                                <input type="text" name="tax_id" value={formData.tax_id} onChange={handleChange} placeholder="Steuer-ID" />
                            </>
                        )}
                        <label htmlFor='first_name'>Vorname</label>
                        <input id='first_name' type="text" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="Vorname" />
                        <label htmlFor='last_name'>Nachname</label>
                        <input id='last_name' type="text" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="Nachname" />
                        <label htmlFor='street'>Straße</label>
                        <input type="text" name="street" value={formData.street} onChange={handleChange} placeholder="Straße" />
                        <label htmlFor="house_no">Hausnummer</label>
                        <input type="text" name="house_no" value={formData.house_no} onChange={handleChange} placeholder="Hausnummer" />
                        <label htmlFor="city">Ort</label>
                        <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Stadt" />
                        <label htmlFor="zip_code">PLZ</label>
                        <input type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} placeholder="PLZ" />
                        <DefaultButton text="Speichern" onClick={handleSave} />
                    </div>
                ) : (
                    <div className="profile-details">
                        <p><strong>Name:</strong> {customerProfile?.user.first_name} {customerProfile?.user.last_name}</p>
                        {!customerProfile?.is_private_person && (
                            <>
                                <p><strong>Firma:</strong> {customerProfile?.company_name || "Nicht angegeben"}</p>
                                <p><strong>Steuer-ID:</strong> {customerProfile?.tax_id || "Nicht angegeben"}</p>
                            </>
                        )}
                        <p><strong>Adresse:</strong> {customerProfile?.street} {customerProfile?.house_no}, {customerProfile?.zip_code} {customerProfile?.city}</p>
                        <DefaultButton text="Bearbeiten" onClick={() => setIsEditing(true)} />
                    </div>
                )}

                <DefaultButton className="logout-button" text="Logout" onClick={handleLogout} />
            </div>

            <div className='bookings-container'>
                <h2>Meine Buchungen</h2>
                {!customerProfile ? (
                    <p>Lade Buchungen...</p>
                ) : customerProfile.booked_courses.length === 0 ? (
                    <p>Wenn Kurse gebucht und bezahlt sind, werden hier alle wichtigen Details angezeigt.</p>
                ) : (
                    <ul>
                        {customerProfile.booked_courses.map(course => {
                            // Formatierung für Datum und Uhrzeit
                            const formattedStartDate = new Date(course.start_date).toLocaleDateString('de-DE');
                            const formattedEndDate = new Date(course.end_date).toLocaleDateString('de-DE');
                            const formattedStartTime = course.start_time.slice(0, 5); // 'HH:mm'
                            const formattedEndTime = course.end_time.slice(0, 5); // 'HH:mm'

                            return (
                                <li key={course.id} className="course-item">
                                    <h3>{course.course} {course.title}</h3>
                                    <p><strong>Startdatum:</strong> {formattedStartDate}</p>
                                    <p><strong>Enddatum:</strong> {formattedEndDate}</p>
                                    <p><strong>Uhrzeit:</strong> Täglich von {formattedStartTime} Uhr bis {formattedEndTime} Uhr</p>

                                    <Link to={course.document_path} target="_blank" rel="noopener noreferrer">
                                        <DefaultButton text="Zu den Dateien" />
                                    </Link>

                                    <Link to={course.zoom_link} target="_blank" rel="noopener noreferrer">
                                        <DefaultButton text="Zoom Meeting" />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Profile;
