import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import DefaultButton from '../../components/buttons/default-button/default-button';
import './booking-summary.css';

function BookingSummary() {
  const { dateId } = useParams();  // ID des ausgewählten Kurs-Termins
  const [dateDetails, setDateDetails] = useState(null);
  const [customerProfile, setCustomerProfile] = useState(null);
  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // API-Requests: Kurs-Termin-Details und Teilnehmerinformationen laden
    fetch(`${API_BASE_URL}/api/dates/${dateId}`)
      .then(response => response.json())
      .then(data => setDateDetails(data))
      .catch(error => console.error('Error fetching date details:', error));

    fetch(`${API_BASE_URL}/api/auth/customer-profile/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
          setCustomerProfile(data);
          setCustomer(data.user);
      })
      .catch(error => console.error('Error fetching customer profile:', error));
  }, [dateId]);

  const handleConfirmPurchase = () => {
    // **🔍 Auth-Token überprüfen**
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error("Kein Auth-Token gefunden!");
      return;
    }

    // **🔹 Buchungsdaten korrekt erstellen**
    const bookingData = {
      date: dateId,
      customer_profile: customerProfile.id,
      customer: customer.id
    };

    console.log("📩 Sende Buchung:", bookingData);

    fetch(`${API_BASE_URL}/api/booking/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,  // ✅ Richtiger Token
      },
      body: JSON.stringify(bookingData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          navigate('/thank-you');
        } else {
          console.error("Fehler bei der Buchung:", data);
          alert('⚠️ Es gab ein Problem bei der Buchung.');
        }
      })
      .catch(error => {
        console.error('Error creating booking:', error);
        alert("⚠️ Serverfehler! Bitte versuchen Sie es später erneut.");
      });
  };

  if (!dateDetails || !customerProfile) {
    return <p>Lade Buchungsdetails...</p>;
  }

  return (
    <div className="booking-summary-container">
      <h1 className='bsh1'>Buchungsübersicht</h1>

      <div className="booking-details">
        <h2 className='bsh2'>Kursinformationen</h2>
        <p><strong>Kursname:</strong> {dateDetails.courses_title}</p>
        <p><strong>Startdatum:</strong> {dateDetails.formatted_start_date}</p>
        <p><strong>Enddatum:</strong> {dateDetails.formatted_end_date}</p>
        <p><strong>Uhrzeit:</strong> {dateDetails.start_time.split(':').slice(0, 2).join(':')} - {dateDetails.end_time.split(':').slice(0, 2).join(':')} Uhr</p>
        <p><strong>Gesamtpreis:</strong> {dateDetails.total_price} €</p>
      </div>

      <div className="customer-details">
        <h2 className='bsh2'>Teilnehmerinformationen</h2>
        <p><strong>Name:</strong> {customerProfile.user.first_name} {customerProfile.user.last_name}</p>
        <p><strong>Adresse:</strong> {customerProfile.street} {customerProfile.house_no}, {customerProfile.zip_code} {customerProfile.city}</p>
        <p><strong>E-Mail:</strong> {customerProfile.user.email}</p>
        {customerProfile.company_name && (
            <p><strong>Firma:</strong> {customerProfile.company_name}</p>
        )}
      </div>

      <div className="confirm-purchase">
        <DefaultButton text="Jetzt kaufen" onClick={handleConfirmPurchase} />
      </div>
    </div>
  );
}

export default BookingSummary;
