import { useState } from "react";
import API_BASE_URL from "../../config";  // Deine API-URL
import "./contact.css";  // Importiere das CSS
import DefaultButton from "../../components/buttons/default-button/default-button";

function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const [status, setStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        try {
            const response = await fetch(`${API_BASE_URL}/api/contact/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setStatus("Ihre Nachricht wurde gesendet, wir melden uns in kürze bei Ihnen.");
                setFormData({ name: "", email: "", message: "" });
            } else {
                setStatus("Fehler beim senden der Nachricht. Bitte versuche es später erneut.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setStatus("Fehler beim senden der Nachricht. Bitte versuche es später erneut.");
        }
    };

    return (
        <div className="contact-container">
            <h2>Fragen? Schreiben Sie uns.</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                    Nachricht:
                    <textarea name="message" value={formData.message} onChange={handleChange} required />
                </label>
                <DefaultButton text="Senden" type="submit" />
            </form>
            {status && <p className="contact-status">{status}</p>}
        </div>
    );
}

export default ContactForm;
