import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DefaultButton from '../../components/buttons/default-button/default-button';
import './thank-you.css';

function ThankYou() {
  const navigate = useNavigate();
  const location = useLocation();
  const bookingDetails = location.state || {};

  useEffect(() => {
    // Automatische Weiterleitung zur Startseite nach 10 Sekunden
    const timer = setTimeout(() => {
      navigate('/training-courses-lp');
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h2>🎉 Vielen Dank für Ihre Buchung!</h2>
        <p className='TYp'>Ihre Buchung war erfolgreich. Sie erhalten in Kürze eine Bestätigungs-E-Mail mit der Rechnung.</p>

        {bookingDetails.invoice_number && (
          <p className='TYp'><strong>Rechnungsnummer:</strong> {bookingDetails.invoice_number}</p>
        )}


        <p className='TYp'>Bitte überweisen Sie den Rechnungsbetrag an die angegebene Bankverbindung in Ihrer Rechnung.</p>

        <DefaultButton
          text="Zurück zur Startseite"
          onClick={() => navigate('/')}
        />
      </div>
    </div>
  );
}

export default ThankYou;
