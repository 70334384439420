import React, {useState, useEffect} from 'react'
import './training-courses.css'

import CourseElement from '../../components/training-items/course-element.jsx'
import API_BASE_URL from '../../config.js';

function TrainingCourses() {

  const [trainingCourses, setTrainingCourses] = useState([]);

  const fetchTrainingCourses = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/training-courses/`); // URL deiner REST-API
        const data = await response.json();
        setTrainingCourses(data);
    } catch (error) {
        console.error('Fehler beim Abrufen der Navigationslinks:', error);
    }
  };

  useEffect(() => {
    fetchTrainingCourses();
  }, []);

  return (
    <div className="main">
      {trainingCourses.map((courses) =>
        <CourseElement
        key={courses.id}
        logo_path={courses.logo_path}
        title={courses.title}
        course_count={`${courses.course_count} ${(courses.course_count === 1) ? 'Kurs' : 'Kurse'}`}
        slug={courses.training_slug}
        btn_text='Zu den Kursen'
        />
      )}

    </div>
  )
}

export default TrainingCourses
